'use strict';

angular.module('enervexSalesappApp').controller('CacheCtrl', function($scope, Cache, _) {
	$scope.allEntries = [];
	function fetch() {
		Cache.query({}).$promise.then(function(entries){
			$scope.allEntries = entries;
			$scope.entries = $scope.allEntries
		});
	}
	fetch();
	$scope.refresh = function() {
		fetch();
	}
	$scope.delete = function(key) {
		Cache.delete({
			id: key
		});
		$scope.allEntries = _.filter($scope.allEntries, function(r){
			return r != key
		})
		// $scope.applyFilter()
		$scope.entries = $scope.allEntries;
	}
	$scope.clear = function() {
		Cache.deleteAll({
		});
		$scope.allEntries = []
		// $scope.applyFilter()
		$scope.entries = $scope.allEntries;
	}
	// $scope.applyFilter = function() {
	// 	var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

	// 	$scope.fanPhases = _.filter($scope.allApplications, function(rule){
	// 		if (nameReg && !(nameReg.test(rule.action) || nameReg.test(rule.filter) || (rule.product && nameReg.test(rule.product.name)) || nameReg.test(rule.legacyId) )) {
	// 			return false;
	// 		} else if ($scope.category && $scope.category != '' && $scope.category != rule.category) {
	// 			return false;
	// 		} else {
	// 			return true
	// 		}
	// 	})
	// }
});
